
a.menu-active > p {
  font-weight: 300;
  border-bottom: 2px solid white;
}

.menu-collapse-active {
  font-weight: 300;
  color: white !important;
  background-color: #002d35;
}
// navbar
.menu-container {
  font-weight: 300;
  color: white !important;
  background-color: #002d35 !important;
  z-index: 9;
}
// register btn
.btn-regis {
  font-size: 14px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-style: solid;
  // border-width: 1px;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #fcf6bd, #d1a033);
  font-family: "DB Helvethaica X" !important;
}
//toggle btn
.nave-toggle {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 0;
  width: 90px;
  background-color: #002d35;
  i {
    margin: auto;
    display: block;
    width: fit-content;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 25px;
  }
  i.dark-blue {
    color: #22334e;
  }
}
//callapse
.callapse-new {
  position: fixed;
  z-index: 9999;
  top: 76px;
  background: white;
  right: 0px;
  @media (min-width: 992px) {
    display: none;
  }
}
//logo menu
.logo-sirivirin {
  @media (max-width: 991px) {
    margin: auto;
  }
}
