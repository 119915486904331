/*------------------------------------------------------------------
[ Variable Global ]*/
$family: "DB Helvethaica X";
$familyLight: "DB Helvethaica X Light";
$familyMedium: "DB Helvethaica X Medium";
$familyBold: "DB Helvethaica X bold";
$public_url: "";
//$public_url: "/sirivirin";
/*------------------------------------------------------------------
[ Class extend ]*/
.font-size-18 {
  font-size: 18pt;
}
.shadow-hover {
  -webkit-box-shadow: 0px 4px 0px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 0px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 0px -1px rgba(0, 0, 0, 0.15);
}

/*------------------------------------------------------------------
[ Global ]*/
@font-face {
  font-family: $family;
  src: url($public_url+"/font/DB-Helvethaica-X-v3.2.ttf") format("truetype");
}
@font-face {
  font-family: $familyMedium;
  src: url($public_url+"/font/DB-Helvethaica-X-Med.ttf") format("truetype");
}
@font-face {
  font-family: $familyLight;
  src: url($public_url+"/font/DB-Helvethaica-X-Li.ttf") format("truetype");
}
@font-face {
  font-family: $familyBold;
  src: url($public_url+"/font/DB-Helvethaica-X-Bd-v3.2.ttf") format("truetype");
}
body,
html {
  font-family: $family, sans-serif;
  color: #666666;
  font-size: 18px;
  line-height: 1.8em;
  height: 100%;
  @media (max-width: 991.98px) {
    font-size: 16px;
  }
  @media (max-width: 767.98px) {
    font-size: 15px;
  }
}
section.auto-height {
  height: auto;
}
section {
  height: 100%;
}

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
h1 {
  @media (max-width: 991.98px) {
    font-size: 35px;
  }
  font-family: $familyBold;
  font-size: 36pt !important;
}
h2 {
  font-family: $familyBold;
  font-size: 28pt !important;
}
h3 {
  font-family: $familyBold;
  font-size: 24pt !important;
}
h4,
h5 {
  font-family: $familyBold;
  font-size: 18pt !important;
}
h6 {
  font-family: $familyBold;
  font-size: 14pt !important;
}

a {
  color: unset;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  font-size: 18pt !important;
}
a:hover {
  color: unset;
  filter: brightness(0.9);
  text-decoration: none;
}
a:focus {
  outline: none;
}
span {
  @extend .font-size-18;
}
p {
  @extend .font-size-18;
}
li {
  font-family: $familyLight;
  font-size: 24px;
}
button {
  font-size: 18px !important;
  height: 35px;
  font-family: $family;
  &:hover {
    @extend .shadow-hover;
  }
}
.text-db-light {
  font-family: $familyLight;
}
.text-db-bold {
  font-family: $familyBold;
}
.pointer {
  cursor: pointer;
}
/*------------------------------------------------------------------
[ text ]*/
.text-indent {
  text-indent: 3em;
}
.text-green1 {
  color: #96c09c;
}
.text-green2 {
  color: #619268;
}
.text-brown {
  color: #c7b263;
}
.text-yellow {
  color: #fffabe;
}
.text-dark-blue {
  color: #22334e !important;
}
.text-center-vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.text-white {
  color: #fff;
}
/*------------------------------------------------------------------
[ list ]*/
ul.house-type {
  li {
    list-style-type: none;
    &:before {
      content: "-";
      // font-size: 12px;
      padding-right: 5px;
    }
  }
}

/*------------------------------------------------------------------
[ background ]*/

.style {
  width: 90vw;
  height: 90vh;
  text-align: center;
  padding-top: calc(50vh - 50px);
  margin-left: 5vw;
  border: 4px double #f00;
  background-color: #000;
}
.bg-green0 {
  background-color: #edf5ed;
}
.bg-green1 {
  background-color: #96c09c;
}
.bg-green1-op {
  background: rgba(150, 192, 156, 0.7);
}
.bg-green2 {
  background-color: #619268;
}
.bg-green2-op {
  background: rgba(97, 146, 104, 0.7);
}
.bg-green3 {
  background-color: #5e7e62;
}
.bg-green4 {
  background-color: #515c53;
}
.bg-brown {
  background-color: #c7b263;
}
.bg-yellow {
  background-color: #fffabe;
}
.bg-gray {
  background-color: #eeeeee;
}
.bg-white-op {
  background-color: #fff;
}
.bg-dark-blue {
  background-color: #002d35;
}
.bg-golden {
  background-image: linear-gradient(to left, #d1a033, #fcf6bd 26%, #d1a033 50%, #fcf6bd 77%, #d1a033);
}
.bg-banner {
  background: linear-gradient(0deg, rgba(150, 192, 156, 0.8), rgba(150, 192, 156, 0.8)),
    url($public_url+"/asset/images/ele BC new.jpg") no-repeat;
  background: -webkit-linear-gradient(0deg, rgba(150, 192, 156, 0.8), rgba(150, 192, 156, 0.8)),
    url($public_url+"/asset/images/ele BC new.jpg") no-repeat;
  background: -moz-linear-gradient(0deg, rgba(150, 192, 156, 0.8), rgba(150, 192, 156, 0.8)),
    url($public_url+"/asset/images/ele BC new.jpg") no-repeat;
  background: -ms-linear-gradient(0deg, rgba(150, 192, 156, 0.8), rgba(150, 192, 156, 0.8)),
    url($public_url+"/asset/images/ele BC new.jpg") no-repeat;
  background: -o-linear-gradient(0deg, rgba(150, 192, 156, 0.8), rgba(150, 192, 156, 0.8)),
    url($public_url+"/asset/images/ele BC new.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 56px);
}
.bg-cover-vdo {
  background-image: url($public_url+"/asset/images/section_banner/bg-desktop.jpg");
  background-position: center center;
  background-size: cover;
}
.bg-banner-1 {
  // background-image: url($public_url+"/asset/images/section_banner/01-element-banner-promotion.jpg");
  // background-image: url($public_url+"/asset/images/section_banner/01-element-banner-promotion-Desktop.jpg");
  background-position: center center;
  background-size: cover;
}
.bg-image1 {
  background: url($public_url+"/asset/images/image_bg_sectionConcept.jpg") no-repeat;
  background: url($public_url+"/asset/images/image_bg_sectionConcept.jpg") no-repeat;
  background: url($public_url+"/asset/images/image_bg_sectionConcept.jpg") no-repeat;
  background: url($public_url+"/asset/images/image_bg_sectionConcept.jpg") no-repeat;
  background: url($public_url+"/asset/images/image_bg_sectionConcept.jpg") no-repeat;
  // background-position: 50% 0%;
  background-size: cover;
  @media (max-width: 768px) {
    background-position: 76%;
  }
}
.bg-image2 {
  background: url($public_url+"/asset/images/concept.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image3 {
  background: url($public_url+"/asset/images/bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  height: 80vh;
}

.bg-pros {
  .content-pros-hover {
    color: transparent;
    &:hover {
      @extend .hover-pros;
    }
  }
}

.bg-pros-1 {
  background: url($public_url+"/asset/images/image_Hilight/01-hilight.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  .content-pros-hover {
    color: transparent;
    &:hover {
      @extend .hover-pros;
    }
  }
}

.bg-pros-2 {
  background: url($public_url+"/asset/images/image_Hilight/02-hilight.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  .content-pros-hover {
    color: transparent;
    &:hover {
      @extend .hover-pros;
    }
  }
}

.bg-pros-3 {
  background: url($public_url+"/asset/images/image_Hilight/03-hilight.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  .content-pros-hover {
    color: transparent;
    &:hover {
      @extend .hover-pros;
    }
  }
}

.bg-pros-4 {
  background: url($public_url+"/asset/images/image_Hilight/05-hilight.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  .content-pros-hover {
    color: transparent;
    &:hover {
      @extend .hover-pros;
    }
  }
}

.bg-pros-5 {
  background: url($public_url+"/asset/images/image_Hilight/04-hilight.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  .content-pros-hover {
    color: transparent;
    &:hover {
      @extend .hover-pros;
    }
  }
}

.bg-pros-6 {
  background: url($public_url+"/asset/images/image_Hilight/06-hilight.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  .content-pros-hover {
    color: transparent;
    &:hover {
      @extend .hover-pros;
    }
  }
}
.bg-text-block-content {
  p {
    font-size: 24px;
  }
  padding: 0 60px;
  @media (min-width: 992px) {
    width: 960px;
    padding-left: 150px;
    padding-right: 70px;
  }
  @media (max-width: 768px) {
    width: 960px;
    height: 400px;
    padding: 0 20px;
  }
  @media (max-width: 576px) {
    width: 960px;
    height: 550px;
    padding: 0 20px;
  }
  width: 100%;
  height: 277px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 45, 53, 0.9);
}

.bg-map {
  height: 60vh;
  width: 100%;
  background-repeat: no-repeat;
  background-image: url($public_url+"/asset/images/section_map/image_map_graphic.jpg");
  background-position: center;
}
/*------------------------------------------------------------------
[ Input ]*/

input,
textarea {
  outline: none;
  border: none;
}
input::-webkit-input-placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: #cccccc;
}
.input-box {
  font-size: 18px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  background: #ffffff;
  height: 36px;
  border: 1px solid #1b1136;
  border-radius: 3px;
  padding: 0 0 0 10px;
  font-size: 18px;
  font-weight: 300;
}
.textarea-box {
  font-size: 18px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  background: #ffffff;
  height: 150px;
  border: 1px solid #1b1136;
  border-radius: 3px;
  padding: 20px 30px 20px 10px;
  font-weight: 300;
}
.select-box select {
  width: 100%;
  padding: 0 0 0 10px;
  height: 36px;
  border: 0;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #1b1136;
  color: #666666;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMjU1IDI1NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjU1IDI1NTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJhcnJvdy1kcm9wLWRvd24iPgoJCTxwb2x5Z29uIHBvaW50cz0iMCw2My43NSAxMjcuNSwxOTEuMjUgMjU1LDYzLjc1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  font-size: 18px;
  font-weight: 300;
}
.select-box select:focus {
  outline: none;
}
.select_arrow {
  position: absolute;
  top: 20px;
  right: 20px;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0px 5px;
  border-color: #7b7b7b transparent transparent transparent;
}
.focus-input-box {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: #aa2aff;
}
.input-box:focus + .focus-input-box,
.textarea-box:focus + .focus-input-box,
.select-box select:focus + .focus-input-box {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}
.symbol-input-box {
  font-size: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.input-box:focus + .focus-input-box + .symbol-input-box {
  color: #ffb82a;
  padding-left: 28px;
}

/*------------------------------------------------------------------
[ hover ]*/
.hov1:hover {
  color: white !important;
  filter: brightness(0.9);
  cursor: pointer;
  background-color: #002d35 !important;
}
.hov2:hover {
  color: #002d35 !important;
  // filter: brightness(0.9);
  cursor: pointer;
  background-color: white !important;
  border-color: #002d35 !important;
  border-image-source: none;
  border-width: 1px;
}
.hov3:hover {
  filter: brightness(0.9);
  cursor: pointer;
}

.hover-pros {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
  background-image: linear-gradient(to bottom, rgba(0, 89, 101, 0), #002d35);
  color: white;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.icon-input-clock::after {
  content: "\f017";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: block;
  position: absolute;
  color: #1b1136;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

/*------------------------------------------------------------------
[ datepicker ]*/
.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  display: flex !important;
}

.react-datepicker__time-container {
  float: unset !important;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 0px !important;
}

.react-datepicker__month-container {
  float: unset !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #aeaeae;
  width: 100% !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  overflow-y: hidden !important;
  margin: unset !important;
  text-align: center;
  width: 269.53px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  height: 100% !important;
  overflow-x: scroll !important;
  overflow-y: unset !important;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: content-box;
}

/*------------------------------------------------------------------
[ other ]*/
.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: white !important;
  border: solid 1px #22334e !important;
}

.swiper-pagination-bullet-active {
  background: #22334e !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 0px !important;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0px !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: none !important;
  color: black !important;
  outline: none;
}
.swiper-wrapper div img {
  height: auto !important;
  max-height: 100% !important;
}

.fas.fa-chevron-left.fa-2x.text-white.swiper-button-prev.pr-3::before {
  @media (max-width: 767.98px) {
    display: none;
  }
}
.fas.fa-chevron-right.fa-2x.text-white.swiper-button-next.pr-3 {
  @media (max-width: 767.98px) {
    display: none;
  }
}
.swiper-container.swiper-container-initialized.swiper-container-horizontal {
  height: 100%;
}

.swiper-wrapper {
  padding-bottom: 2.5rem;
  div {
    img {
      height: 100%;
    }
  }
}

/*------------------------------------------------------------------
[ table ]*/
.typeHome .table-sm thead tr td {
  padding-bottom: 8px;
}
.typeHome .table-sm td,
.table-sm th {
  padding: 0px;
}
.typeHome .table-sm tr:nth-child(3n) td:last-child {
  padding-bottom: 20px;
}

.table-sm td,
.table-sm th {
  font-size: 16px;
  padding: 0 !important;
  @media (max-width: 767.98px) {
    font-size: 15px;
  }
}

.table-sm tbody tr td:nth-child(2n) {
  width: 10%;
  white-space: nowrap;
}

// Text
p {
  text-align: justify;
}

// UL

.ul-style-none {
  list-style-type: none;
}

// font banner

.font-banner {
  font-size: 2.5rem;
  @media (max-width: 767.98px) {
    font-size: 1.5rem;
  }
}
//underline
.underline-head {
  height: 2px;
}
.underline-content {
  height: 5px;
}
.underline-house-type {
  height: 3px;
}
.underline-footer {
  height: 2px;
}

//header
.header-1 {
  font-size: 2rem;
}
//border-gradient
.border-golden {
  border-style: solid;
  border-width: 1.5px;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #fcf6bd, #d1a033);
}

.border-dark-bule {
  border-style: solid;
  border-width: 1px;
  border-image-slice: 1;
  border-color: #1b1136;
}

//fit contet el ul
.content-fit {
  width: fit-content;
}

//menu contact
.menu-contact {
  z-index: 999;
  display: none;
  @media (max-width: 767.98px) {
    top: 370px;
    width: 60px;
    font-size: 25px;
    z-index: 9;
    display: block;
  }
  font-size: 35px;
  top: 500px;
  right: 0;
  width: 94px;
  .icon-contact-main {
    @media (max-width: 767.98px) {
      height: 50px;
    }
    height: 77px;
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-radius: 500px 0 0 500px;
    .icon-contact {
      display: block;
      margin: auto;
      width: fit-content;
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

//shadow
.shadow-heavy {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
}

//fix lightbok
.footerCount_lkhc9u {
  display: none;
}

.callapse-area {
  background-color: transparent;
  position: fixed;
  z-index: 99;
}

.player-wrapper {
  position: relative;
  padding-top: 30.25%;
  .react-player {
    position: absolute;
    top: 0;
  }
}

/*  fix swiper padding-bottom */
.swiper-wrapper-banner .swiper-wrapper {
  padding-bottom: 0px;
}

.swiper-wrapper .iframe-main {
  top: 0;
}

.swiper-pagination {
  display: none;
}

.play-main {
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

video {
  background-color: black;
}
.table-center-detail-house {
  width: 83%;
  margin: 0 auto;
}
.modal-header {
  border-bottom-color: transparent !important;
}

.section-album {
  height: auto;
  @media (min-height: 690px) and (min-width: 992px) {
    height: 100%;
  }
}
.gm-style-cc > div > a {
  display: none !important;
}

ul.detail-plan > li {
  font-family: $family, sans-serif;
  font-size: 20px;
}
.img-banner-1 {
  height: 80%;
}
.text-banner-1-1 {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: white;
  background-color: #002d35;
}
.text-banner-1-2 {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: red;
  background-color: #93b8af;
}

.reps-section {
  height: auto !important;
  // @media (max-width: 517px) {
  //   height: 100% !important;
  // }
}
.reps-banner {
  height: auto !important;
  // @media (max-width: 517px) {
  //   height: 100% !important;
  // }
}

.padding-nav {
  padding-top: 53px !important;
  @media (max-width: 991px) {
    padding-top: 76.258px !important;
  }
}

.gellery-img{
  height: auto !important;
  @media (max-width:767px) {
    height: 100% !important;
  }
}
